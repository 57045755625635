import axios from 'axios';
import { jwtDecode } from 'jwt-decode'
import { useMemo, useState, useEffect, useCallback } from 'react';
import {
  AppState,
  useAuth0,
  Auth0Provider,
  LogoutOptions,
  PopupLoginOptions,
} from '@auth0/auth0-react';

import { AUTH0_API } from 'src/config-global';

import { AuthContext } from './auth-context';
import { useRouter } from '../../../routes/hooks';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

function AuthProviderWrapper({ children }: Props) {
  const { isAuthenticated, user, isLoading, loginWithRedirect, loginWithPopup, logout, getAccessTokenSilently } = useAuth0();
  const [popupClick, setPopupClick] = useState(true);
  const [userRoles, setUserRoles] = useState<string[]>();
  const router = useRouter();

  // LOGIN
  const handleLoginWithPopup = useCallback(
    async (options?: PopupLoginOptions) => {
      loginWithPopup?.(options);
      setPopupClick(false);
    },
    [loginWithPopup]
  );

  // LOGOUT
  const handleLogout = useCallback(
    async (options?: LogoutOptions) => {
      logout?.(options);
    },
    [logout]
  );

  useEffect(() => {
    const getAuthInfo = async () => {
      try {
        // get bearer token, otherwise auth check will fail with status 401
        const token = await getAccessTokenSilently();

        console.log(token);
        if (!token) {
          console.log("Token is missing, logging out");
          await logout({ logoutParams: { returnTo: "https://secure.meridian-counseling.com/auth/external-login" } });
          return;
        }

        const decoded = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        if (decoded.exp && decoded.exp < currentTime) {
          console.log("Token is expired, logging out");
          await logout({ logoutParams: { returnTo: "https://secure.meridian-counseling.com/auth/external-login" } });
          return;
        }

        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        //  check user's authorization. Throws an error if not authorized
        const response = await axios.get(`${import.meta.env.VITE_API_URL}/auth`);
        // response.data = ['clinician', 'supervisor'];
        console.log(response.data);
        setUserRoles(response.data);
      } catch(error) {
        if(error && error.response && error.response.status === 403) {
          router.replace("/403");
        }
      }
    };

    console.log('attempting to check authentication')
    if (!isLoading) {
      if (isAuthenticated) {
        console.log("User is authenticated, fetching token.");
        getAuthInfo();
      } else {
        console.log("User is not authenticated.");
        router.replace("/auth/external-login");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  // ----------------------------------------------------------------------

  const checkAuthenticated = isAuthenticated ? 'authenticated' : 'unauthenticated';

  const status = popupClick && isLoading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: {
        ...user,
        displayName: user?.name,
        photoURL: user?.picture,
        roles: userRoles,
      },
      method: 'auth0',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      loginWithRedirect,
      loginWithPopup: handleLoginWithPopup,
      logout: handleLogout,
    }),
    [handleLoginWithPopup, handleLogout, loginWithRedirect, status, user, userRoles]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

// ----------------------------------------------------------------------

export const AuthProvider = ({ children }: Props) => {
  const domain = AUTH0_API.domain ?? '';

  const clientId = AUTH0_API.clientId ?? '';

  const redirectUri = AUTH0_API.callbackUrl ?? '';

  const audienceName = AUTH0_API.audience ?? '';

  const onRedirectCallback = useCallback((appState?: AppState) => {
    console.log(appState);
    window.location.replace(appState?.returnTo || window.location.pathname);
  }, []);

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: audienceName,
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      <AuthProviderWrapper>{children}</AuthProviderWrapper>
    </Auth0Provider>
  );
};
