import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';

import SvgColor from 'src/components/svg-color';

import { useAuthContext } from '../../auth/hooks';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),

  // new icons from here on down
  profile: icon('profile'),
  chart: icon('chart'),
  home: icon('home'),
  list: icon('list'),
  matching: icon('matching'),
  usage: icon('usage'),
  barGraph: icon('barGraph'),
  funnel: icon('funnel'),
  arrowRight: icon('arrowRight'),
  editNote: icon('editNote'),
  peopleOutline: icon('peopleOutline'),
};


// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useTranslate();
  const clientIntakeFlow = import.meta.env.VITE_CLIENT_INTAKE_FLOW;
  const providerFlow = import.meta.env.VITE_PROVIDER_FLOW;
  const clientReferralFlow = import.meta.env.VITE_CLIENT_REFERRAL;
  const { user } = useAuthContext();
  const data = useMemo(() => {
    const sections = [];

    // TODO come up with a better way to hide/show instead of a bunch of if statements

    // Add "admins" section if clientIntakeFlow is ON
    if (clientIntakeFlow === "ON") {
      sections.push({
        subheader: t('admin'),
        roles: ['biller', 'scheduler', 'practice_manager', 'clinician_admin', 'clinician_full'],
        items: [
          {
            title: 'Meridian Home',
            path: paths.dashboard.root,
            icon: ICONS.home,
          },
          {
            title: "Client Intake",
            path: paths.dashboard.clientIntake,
            icon: ICONS.funnel,
          },
          {
            title: "Provider Roster",
            path: paths.dashboard.providerRoster,
            icon: ICONS.list,
          },
          {
            title: "Client Provider Matching",
            path: paths.dashboard.clientProviderMatching,
            icon: ICONS.matching,
          },
        ],
      });
    }


    if (providerFlow === 'ON') {
      if(clientReferralFlow === "ON") {
          sections.push({
            subheader: t('providers'),
            roles: ['supervisor'],
            items: [
              {
                title: 'Meridian Home',
                path: paths.dashboard.root,
                icon: ICONS.home,
              },
              {
                title: 'Provider Profile',
                path: paths.dashboard.providerProfile,
                icon: ICONS.profile,
              },
              {
                title: 'Provider Caseload',
                path: paths.dashboard.providerCaseload,
                icon: ICONS.peopleOutline,
              },
              {
                title: "Client Referral Requests",
                path: paths.dashboard.referralRequestsProvider,
                icon: ICONS.editNote,
              },
              {
                title: "Quality",
                icon: ICONS.dashboard,
                children: [
                  {
                    title: "Missing Notes",
                    path: paths.dashboard.analyticsMissingNotes,
                  },
                  {
                    title: "Treatment Plans",
                    path: paths.dashboard.analyticsTreatmentPlans,
                  },
                  {
                    title: "Supervisors",
                    path: paths.dashboard.analyticsSupervisors,
                  }
                ]
              },
            ],
          });

        if(user && user.roles && !user.roles.includes('supervisor'))
          sections.push({
            subheader: t('providers'),
            roles: ['clinician', 'clinician_admin', 'clinician_self', 'clinician_full'],
            items: [
              {
                title: 'Meridian Home',
                path: paths.dashboard.root,
                icon: ICONS.home,
              },
              {
                title: 'Provider Profile',
                path: paths.dashboard.providerProfile,
                icon: ICONS.profile,
              },
              {
                title: 'Provider Caseload',
                path: paths.dashboard.providerCaseload,
                icon: ICONS.peopleOutline,
              },
              {
                title: "Client Referral Requests",
                path: paths.dashboard.referralRequestsProvider,
                icon: ICONS.editNote,
              },
              {
                title: "Quality",
                icon: ICONS.dashboard,
                children: [
                  {
                    title: "Missing Notes",
                    path: paths.dashboard.analyticsMissingNotes,
                  },
                  {
                    title: "Treatment Plans",
                    path: paths.dashboard.analyticsTreatmentPlans,
                  },
                ]
              },
            ],
          });

      }
      else {
        sections.push({
          subheader: t('providers'),
          roles: ['supervisor'],
          items: [
            {
              title: 'Meridian Home',
              path: paths.dashboard.root,
              icon: ICONS.home,
            },
            {
              title: 'Provider Profile',
              path: paths.dashboard.providerProfile,
              icon: ICONS.profile,
            },
            {
              title: 'Provider Caseload',
              path: paths.dashboard.providerCaseload,
              icon: ICONS.peopleOutline,
            },
            {
              title: "Quality",
              path: paths.dashboard.analytics,
              icon: ICONS.dashboard,
              children: [
                {
                  title: "Missing Notes",
                  path: paths.dashboard.analyticsMissingNotes,
                },
                {
                  title: "Treatment Plans",
                  path: paths.dashboard.analyticsTreatmentPlans,
                },
                {
                  title: "Supervisors",
                  path: paths.dashboard.analyticsSupervisors,
                }
              ]
            },
          ],
        });
        if(user && user.roles && !user.roles.includes('supervisor'))
          sections.push({
            subheader: t('providers'),
            roles: ['clinician', 'clinician_admin', 'clinician_self', 'clinician_full'],
            items: [
              {
                title: 'Meridian Home',
                path: paths.dashboard.root,
                icon: ICONS.home,
              },
              {
                title: 'Provider Profile',
                path: paths.dashboard.providerProfile,
                icon: ICONS.profile,
              },
              {
                title: 'Provider Caseload',
                path: paths.dashboard.providerCaseload,
                icon: ICONS.peopleOutline,
              },
              {
                title: "Quality",
                path: paths.dashboard.analytics,
                icon: ICONS.dashboard,
                children: [
                  {
                    title: "Missing Notes",
                    path: paths.dashboard.analyticsMissingNotes,
                  },
                  {
                    title: "Treatment Plans",
                    path: paths.dashboard.analyticsTreatmentPlans,
                  },
                ]
              },
            ],
          });

      }

    }

    return sections;
  }, [user, clientIntakeFlow, providerFlow, t, clientReferralFlow]);

  return data;
}
